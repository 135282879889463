import React, {useState, useEffect} from "react"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { auth, googleProviderId, facebookProviderId, emailProviderId } from "./firebase";
import Link from '@mui/material/Link';

function LoginDialog() {
  const [open, setOpen] = useState(false)
  let uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      googleProviderId,
      facebookProviderId,
      emailProviderId,
    ],
    credentialHelper: "none",
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  }

  /**
   <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        LogIn
      </Button>
   */

  return (
    <>
      <Link color="white" underline="none"sx={{fontSize: "16px", marginRight: "2px"}} onClick={() => setOpen(true)}>
        LOGIN
      </Link>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Log in</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={auth}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function Login() {
  const [isSignedIn, setIsSignedIn] = useState(false)

  useEffect(
    () => auth.onAuthStateChanged((user) => setIsSignedIn(!!user)),
    []
  )

  //document.querySelector('label[for="ui-sign-in-name-input"]').textContent = "Username"

  if (!isSignedIn) return <LoginDialog />

  return (
    <>
      <Link
        color="white" 
        underline="none"
        sx={{fontSize: "16px", marginRight: "2px"}}
        onClick={() => auth.signOut()}>
        LOGOUT
      </Link>
    </>
  )
}

export default Login
