import React from "react"
import {useNavigate} from "react-router"
import {makeStyles, useTheme} from "@mui/styles"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Map from "./Map.js"
//import MapSearchBox from "./MapSearchBox.js"
import TopBar from "./TopBar.js"
import Intro from "./Intro.js"
import "./App.css"
import { db } from "./firebase";
import FarmDetail from "./Detail"
import SearchIcon from '@mui/icons-material/Search';
import { getDoc, doc } from "firebase/firestore";

const drawerWidth = "min(100%, 350px)"

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  iconButton: {
    float: "left"
  },
  searchIcon: {
    backgroundColor: "white",
    zIndex: "1000",
    position: "relative",
    borderRadius: "5px",
    color:  theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    }
  }
}))

function FarmInfo(props) {
  const classes = useStyles()
  const theme = useTheme()
  
  return (
    <SwipeableDrawer
      className={classes.drawer}
      anchor="left"
      open={props.showDetail}
      onOpen={() => props.setShowDetail(true)}
      onClose={() => props.setShowDetail(false)}
      classes={{
        paper: classes.drawerPaper,
      }}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => props.setShowDetail(false)}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <FarmDetail farm={props.farm} />
    </SwipeableDrawer>
  )
}

function App(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const [farmId, setFarmId] = React.useState({})
  const [farm, setFarm] = React.useState({})
  const [showDetail, setShowDetail] = React.useState(false)
  const [typeFilter, setTypeFilter] = React.useState("all")
  const [flying, setFlying] = React.useState(false)
  const [showIntro, setShowInto] = React.useState(true)
  const [inSearchMode, setInSearchMode] = React.useState(false);
  const [searchValues, setSearchValues] = React.useState({});

  /**
   * Sets a farm in context, adding it to the url, and flys to the farm.
   * @param id - the id of the farm from the database
   * @param fromSearch - boolean telling the function know if the update came
   *   from a search query, otherwise it came from a mouse click.
   */
  let updateFarm = (id, fromSearch) => {
    setInSearchMode(false);
    if(fromSearch) {
      setFarmId(id);
      setShowInto(false);
      navigate("/farm/" + id)
      setFarm(searchValues)
      setSearchValues({})
    } else {
      setFarmId(id)
      setShowInto(false)
      navigate("/farm/" + id)

      const docRef = doc(db, "test", String(id))
      getDoc(docRef).then(doc => {
        setFarm(doc.data())
      })
    }
  }

  function onChangeSearchValues(v) {
    setSearchValues(v.data[0]);
  }

  let searchContent;
  // if (inSearchMode) {
  //   searchContent = <MapSearchBox 
  //     // toggle search container
  //     hideSearchContent={(value) => setInSearchMode(value)}
  //     searchValues={onChangeSearchValues}
  //     setTypeFilter={setTypeFilter}/>
  // } else {
  //   searchContent = 
  //   <IconButton onClick={() => setInSearchMode(true)} className={classes.iconButton}>
  //     <SearchIcon sx={{ fontSize: 40}} className={classes.searchIcon} />
  //   </IconButton>
  // }

  return (
    <>
      <div className={classes.root}>
        { /*<Intro show={showIntro} />  */ }
        <TopBar setTypeFilter={setTypeFilter} />
        <FarmInfo
          showDetail={showDetail}
          setShowDetail={setShowDetail}
          farm={farm}
        />
        <Map
          setFarm={updateFarm}
          farmType={typeFilter}
          onFlyEnd={() => setShowDetail(true)}
          flying={flying}
          setFlying={setFlying}
          farm={farm}
          farmSearched={updateFarm}
          search={searchValues}
        />
        {searchContent}
      </div>
    </>
  )
}

export default App