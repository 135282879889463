import React from "react"
import { makeStyles } from "@mui/styles"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import FilterListIcon from "@mui/icons-material/FilterList"
import farmTypes from "./farmType"
import LogIn from "./LogIn"
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    minHeight: "56px",
    maxHeight: "56px"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  customToolbar: {
  },
  imageLogo: {
    width: "180px",
    marginLeft: "-14px"
  }
}))

function MenuAppBar(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleSelect(item) {
    if (item) props.setTypeFilter(item)
    setAnchorEl(null)
  }

  function changePointer(e) {
    e.target.style.cursor = 'pointer';
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.customToolbar}>
          <img 
            src="logo.png" 
            className={classes.imageLogo} 
            onClick={() => window.location.href = 'https://counterglow.org'} 
            onMouseEnter={changePointer}>
          </img>
          <Typography variant="h6" className={classes.title}></Typography>
          <div>
            <Link underline="none" color="secondary" href="https://www.counterglow.org/give" sx={{fontSize: "16px", fontWeight: "bold", marginRight: "10px"}}>
              GIVE
            </Link>
            <Link underline="none" color="secondary" href="https://www.counterglow.org/faq"  sx={{fontSize: "16px", fontWeight: "bold",  marginRight: "10px"}}>
              FAQ
            </Link>
            <LogIn />
            <IconButton
              sx={{padding: "0px"}}
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit">
              <FilterListIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={() => setAnchorEl(null)}>
              <MenuItem onClick={() => handleSelect("all")}>All</MenuItem>
              {farmTypes.map((item) => (
                <MenuItem onClick={(e) => handleSelect(item[0])} key={item[0]}>
                  {item[1]}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default MenuAppBar
