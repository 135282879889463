import React, {useState, useEffect} from "react"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import DropFarm from "./DropFarm"
import config from "./config"
import TheMap from "./TheMap";
import { getDoc, doc } from "firebase/firestore";
import { db } from "./firebase";

mapboxgl.accessToken = config.mapBoxKey


function Map(props) {
  const mapRef = React.createRef()
  const gmap = React.useRef(null)
  const [loaded, setLoaded] = useState(false)
  const {flying, setFlying} = props

  useEffect(() => {
    let m = new TheMap(mapRef);
    let map = m.map;

    map.on("click", config.farmLayer, (e) => {
      props.setFarm(e.features[0].properties.id, false)

      // we will start flying to the farm, but we will get more precise coordinates later
      let pos = e.features[0].geometry.coordinates
      map.flyTo({center: pos, zoom: 15, essential: true})
      setFlying(true)
    })

    map.on("mouseenter", config.farmLayer, () => {
      map.getCanvas().style.cursor = "pointer"
    })

    map.on("mouseleave", config.farmLayer, () => {
      map.getCanvas().style.cursor = ""
    })

    map.on("load", () => {
      setLoaded(true)
    })

    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      "bottom-left"
    )

    map.dragRotate.disable()
    map.touchZoomRotate.disableRotation()
    map.addControl(new mapboxgl.NavigationControl(), "bottom-left")

    gmap.current = map

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {search} = props
  useEffect(() => {
    if(Object.keys(search).length !== 0) {
      props.setFarm(search.id, true)
      // If we are here, we successfully pulled the searched facility back
      // from the database.  However, do the async nature of saving props
      // in React, props.farm has not finished saving.  So we use the 
      // search results instead of props.farm.
      let latt = search.lat;
      let long = search.lon;
      if ( !latt || !long) return
      setFlying(true)
      let handler = () => {
        setFlying(false)
        props.onFlyEnd()
      }
      
      gmap.current.flyTo({center: [long, latt], zoom: 15, essential: true})
      gmap.current.on("moveend", handler)
      return () => gmap.current.off("moveend", handler)
    } else {
      // This all feels ugly, but this happens when a user directly inserts a URL with a farm
      // such as map.counterglow.org/farm/128081.

      // First find the farm id
      const id = window.location.href.split('/').reverse()[0];
      if(id !== null && id !== undefined && id !== "") {
        // if we have an id, get the data from firebase so we can get the lat and lon
        const docRef = doc(db, "test", String(id))
        getDoc(docRef).then(doc => {
          const farm = doc.data();
          props.setFarm(id, false)
          let latt = farm.lat;
          let long = farm.lon;
          gmap.current.flyTo({center: [long, latt], zoom: 15, essential: true})
          setFlying(true)
        })
      }
    }
  }, [search])

  const {farmType} = props

  useEffect(() => {
    if (!loaded) return;
    if (farmType === "all") {
      gmap.current.setFilter(config.farmLayer, null)
    } else {
      gmap.current.setFilter(config.farmLayer, [
        "match",
        ["get", "farm_type"],
        [farmType],
        true,
        false,
      ])
    }
  }, [farmType, loaded])

  const {lat, lon} = props.farm
  const {onFlyEnd} = props

  useEffect(() => {
    if (!flying || !lat || !lon) return

    let handler = () => {
      setFlying(false)
      onFlyEnd()
    }

    gmap.current.flyTo({center: [lon, lat], zoom: 15, essential: true})
    gmap.current.on("moveend", handler)

    return () => gmap.current.off("moveend", handler)
  }, [lat, lon, flying, setFlying, onFlyEnd])

  return (
    <>
      <DropFarm map={gmap.current} />
      <div ref={mapRef} className="absolute top right left bottom" />
    </>
  )
}

export default Map
