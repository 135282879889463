import React from "react"

import HomeIcon from "@mui/icons-material/Home"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import LocationSearchingIcon from "@mui/icons-material/LocationSearching"
import StreetviewIcon from "@mui/icons-material/Streetview"
import NoteIcon from "@mui/icons-material/Note"
import Divider from "@mui/material/Divider"
import {Barn, Counter} from "mdi-material-ui"
import ReportFarm from './Report'

function FarmAddress({farm}) {
  return (
    <span>
      {farm.address}
      <br /> {farm.city}
      {farm.city && ","} {farm.state}
    </span>
  )
}

function FarmDetail({farm}) {
  let {lat, lon} = farm
  let gmaps = `https://www.google.com/maps/@?api=1&map_action=map&basemap=satellite&zoom=17&center=${lat},${lon}&query=${lat},${lon}`
  let wiki = farm.wiki || `https://papertrail.counterglow.org/c/facilities/5`

  return (
    <>
      <h3 style={{margin: "-10px 0px 5px 0px", fontSize: "15pt"}}>
        {farm.name || "Unknown Farm"}
      </h3>
      <List>
        <ListItem>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Address"
            secondary={<FarmAddress farm={farm} />}
          />
        </ListItem>
        {farm.farm_type &&
          farm.farm_type.name !== "Mixed" &&
          farm.farm_type.name !== "Unknown" && (
            <ListItem>
              <ListItemIcon>
                <Barn />
              </ListItemIcon>
              <ListItemText
                primary="Farm Type"
                secondary={farm.farm_type.name}
              />
            </ListItem>
          )}
        {farm.extra && farm.extra["Number Of Animals"] && (
          <ListItem>
            <ListItemIcon>
              <Counter />
            </ListItemIcon>
            <ListItemText
              primary="Number Of Animals"
              secondary={farm.extra["Number Of Animals"]}
            />
          </ListItem>
        )}
        <ListItem>
          <ListItemIcon>
            <LocationSearchingIcon />
          </ListItemIcon>
          <ListItemText
            primary="Location"
            secondary={
              <span>
                {farm.lat}, {farm.lon}
              </span>
            }
          />
        </ListItem>
        <Divider style={{margin: "22px"}} />
        <ListItem button component="a" href={wiki} target="_blank">
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText
            primary={farm.wiki ? "View papertrail" : "Add Media/Information"}
          />
        </ListItem>
        <ListItem button component="a" href={gmaps} target="_blank">
          <ListItemIcon>
            <StreetviewIcon />
          </ListItemIcon>
          <ListItemText primary="View on Google Maps" />
        </ListItem>
      </List>
      <ReportFarm farm={farm}/>
    </>
  )
}

export default FarmDetail
