import React from "react"
import { db } from "./firebase";
import { addDoc, collection} from "firebase/firestore";

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"

function ReportFarm({farm}) {
  const [open, setOpen] = React.useState(false)
  const [reason, setReason] = React.useState("")
  const [issue, setIssue] = React.useState("")

  var onSubmit = () => {
    console.log("farm.id=", farm.id)
    addDoc(collection(db, "issues"), {
      farm: farm.id,
      reason: reason,
      issue: issue,
    }).then(()=> {
      setOpen(false);
    }).catch(err => {
      console.log("err=", err)
    })
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        style={{marginTop: "10px"}}
        onClick={() => setOpen(true)}>
        Report an issue
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Report an Issue"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <div
            style={{marginTop: "-18px"}}>
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={issue}
                onChange={(e) => setIssue(e.target.value)}>
                <MenuItem value="Facility type is wrong">
                  Facility type is wrong
                </MenuItem>
                <MenuItem value="Closed/Abandoned">Closed/Abandoned</MenuItem>
                <MenuItem value="Information is wrong">
                  Information is wrong
                </MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            id="outlined-multiline-static"
            label="Describe Issue"
            multiline
            rows={4}
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            style={{marginTop: "10px"}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            color="primary"
            autoFocus
            disabled={!reason || !issue}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReportFarm
