import React from "react"
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent";

const useStyles = makeStyles(() => ({
  imagePopup: {
    padding: "0px",
    width: "100%",
    height: "100%"
  },
  cancelIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    margin:  "5px 15px 0px 0px", 
    color: "white" 
  }
}));

function Intro(props) {
  const [open, setOpen] = React.useState(true)
  const classes = useStyles()

  function changePointer(e) {
    e.target.style.cursor = 'pointer';
  }

  return (
    <Dialog
      maxWidth={'md'}
      open={props.show && open}
      onClose={() => setOpen(false)}>
      <img src="intro-modal.png" className={classes.imagePopup}></img>
      <CancelPresentationIcon 
        fontSize="large" 
        className={classes.cancelIcon} 
        onClick={() => setOpen(false)}
        onMouseEnter={changePointer}
        ></CancelPresentationIcon>
    </Dialog>
  )
}

export default Intro
