import React from "react"

import {makeStyles} from "@mui/styles"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import SpeedDialAction from "@mui/material/SpeedDialAction"
import SpeedDial from "@mui/material/SpeedDial"
import SpeedDialIcon from "@mui/material/SpeedDialIcon"
import Snackbar from "@mui/material/Snackbar"
import Fab from "@mui/material/Fab"
import SaveIcon from "@mui/icons-material/Save"
import Zoom from "@mui/material/Zoom"
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation"
import SvgIcon from "@mui/material/SvgIcon"
import {ReactComponent as ChickenIcon} from "./icons/chickens.svg"
import {ReactComponent as CowsIcon} from "./icons/cows.svg"
import {ReactComponent as DairyIcon} from "./icons/dairy.svg"
import {ReactComponent as PigsIcon} from "./icons/pigs.svg"
import { auth, db } from "./firebase";
import { query, collection, doc, writeBatch } from "firebase/firestore";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: "absolute",
    right: "10px",
    bottom: "30px",
  }
}))

function showPins(pins, map) {
  if (!map) return

  map.getLayer("pins") && map.removeLayer("pins")
  map.getSource("pins") && map.removeSource("pins")

  map.addSource("pins", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: pins.map((pin) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [pin.lng, pin.lat],
        },
        properties: {
          icon: pin.type,
        },
      })),
    },
  })
  map.addLayer({
    id: "pins",
    type: "symbol",
    source: "pins",
    layout: {
      "icon-image": ["get", "icon"],
      "icon-size": [
        "interpolate",
        ["linear"],
        ["zoom"],
        5,
        0.14,
        13,
        0.65,
        18,
        0.9,
      ],
      "icon-allow-overlap": true,
    },
  })
}

function DropFarm({map}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [markers, setMarkers] = React.useState([])
  const [readyToSave, setReadyToSave] = React.useState(false)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarLoginMessageOpen, setSnackbarLoginMessageOpen] = React.useState(false)
  const [isSignedIn, setIsSignedIn] = React.useState(false)

  React.useEffect(
    () => auth.onAuthStateChanged((user) => setIsSignedIn(!!user)),
    []
  )
  
  React.useEffect(
    () =>
      window.debug &&
      query(collection(db, "pins"))
        .onSnapshot(({docs}) =>
          showPins(
            docs.map((doc) => doc.data()),
            map
          )
        ),
    [map]
  )

  let save = () => {
    // TODO: what are these values when a user is not logged in so we can be more specific
    if(auth?.currentUser?.uid === undefined || auth?.currentUser?.uid === null ) {
      setSnackbarLoginMessageOpen(true);
      return;
    }
    setReadyToSave(false)
    markers.forEach(({marker}) => marker.setDraggable(false))
    let batch = writeBatch(db);
    const user = auth.currentUser.uid
    markers.forEach(({marker, type}) => {
      // Get a new pins ref every time because this generates the unique id.
      let pinsRef = doc(collection(db, "pins"))
      batch.set(pinsRef, {
        lat: marker.getLngLat().lat,
        lng: marker.getLngLat().lng,
        type,
        user,
      });
      marker.remove()
    });

    batch.commit().then(function () {
      setSnackbarOpen(true)
      setMarkers([])
    });
  }

  let clickHandler = (action) => {
    const color = {
      pigs: "red",
      cows: "green",
      dairy: "black",
      chickens: "orange",
      other: "purple",
    }

    var el = document.createElement("div")
    el.className = action + "Marker"

    var marker = new mapboxgl.Marker(el, {
      draggable: true,
      color: color[action],
    })
      .setLngLat(map.getCenter())
      .addTo(map)

    setOpen(false)
    setReadyToSave(true)
    setMarkers([...markers, {marker: marker, type: action}])
  }

  // For whatever reason, the Fab save icon below has to have the style added as it is because it was not reading the class name.
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={10000}
        message="Thanks for your submission. We will review it shortly."
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackbarLoginMessageOpen}
        onClose={() => setSnackbarLoginMessageOpen(false)}
        autoHideDuration={10000}
        message="You must be logged in to add a facility."
      />
      <Zoom in={readyToSave} >
        <Fab
          color="default"
          aria-label="add"
          style={{ position: "absolute", right: "75px", bottom: "30px", zIndex: "100"}}
          onClick={save}
          size="medium">
          <CheckCircleOutlineIcon sx={{ color: "#FE383F", fontSize: 50, background: "#010057", borderRadius: "100px"}}/>
        </Fab>
      </Zoom>
      <Zoom>
        <Tooltip title="Add a facility" placement="left-end">
          <SpeedDial
            ariaLabel="Drop pin"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            style={{color: "#FE3B3F"}}
            FabProps={{color: "secondary", size: "medium"}}>
            <SpeedDialAction
              key="other"
              icon=<NotListedLocationIcon />
              tooltipTitle="Unknown"
              onClick={() => clickHandler("Other")}
            />
            <SpeedDialAction
              key="pigs"
              icon=<SvgIcon component={PigsIcon} viewBox="4 0 33 35" />
              tooltipTitle="Pigs"
              onClick={() => clickHandler("Pigs")}
            />
            <SpeedDialAction
              key="cows"
              icon=<SvgIcon component={CowsIcon} viewBox="4 0 37 30" />
              tooltipTitle="Cows (meat)"
              onClick={() => clickHandler("Cows")}
            />
            <SpeedDialAction
              key="dairy"
              icon=<SvgIcon component={DairyIcon} viewBox="8 3 35 25" />
              tooltipTitle="Cows (dairy)"
              onClick={() => clickHandler("Dairy")}
            />
            <SpeedDialAction
              key="chickens"
              icon=<SvgIcon component={ChickenIcon} viewBox="0 0 30 30" />
              tooltipTitle="Chickens"
              onClick={() => clickHandler("Chickens")}
              FabProps={{
                style: {transform: "scale(-1,1)"},
              }}
            />
          </SpeedDial>
        </Tooltip>
      </Zoom>
    </>
  )
}

export default DropFarm
