// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import config from "./config"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

mapboxgl.accessToken = config.mapBoxKey

export default class TheMap {
    
    constructor(mapRef) {
        if(TheMap._instance) {
            return TheMap._instance;
        }
        TheMap._instance = this;
        
        this.map = new mapboxgl.Map({
            container: mapRef.current,
            style: config.mapStyle,
            bounds: [-124.7844079 - 10, 49.3457868, -66.9513812 + 10, 24.7433195],
            attributionControl: false
        });

        this.geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            localGeocoder: coordinatesGeocoder,
            zoom: 14,
            placeholder: "Search city, state, address, county",
            mapboxgl: mapboxgl,
            countries: "us",
            marker: true,
        })

        this.map.addControl(this.geocoder)
      
        // based on https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-geocoder-accept-coordinates/
        var coordinatesGeocoder = function(query) {
        // match anything which looks like a decimal degrees coordinate pair
        let matches = query.match(
            /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
        )
        if (!matches) {
            return null
        }
    
        let lat = Number(matches[1])
        let lng = Number(matches[2])
    
        return [
            {
            center: [lng, lat],
            geometry: {
                type: "Point",
                coordinates: [lng, lat],
            },
            place_name: "Lat: " + lat + " Long: " + lng,
            place_type: ["coordinate"],
            properties: {},
            type: "Feature",
            },
        ]}
    }

    // send text value for mapbox to search on
    query(value) {
        this.geocoder.query(value)
    }
}