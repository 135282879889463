import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Initialize Firebase
var firebaseConfig = {
    apiKey: "AIzaSyDUc77Zylk5sWgyGzdpgXL9nliV5mmEgTI",
    authDomain: "projectcounterglow.firebaseapp.com",
    databaseURL: "https://projectcounterglow.firebaseio.com",
    projectId: "projectcounterglow",
    storageBucket: "projectcounterglow.appspot.com",
    messagingSenderId: "961323733135",
    appId: "1:961323733135:web:9c2001607889f4808a60fa",
    measurementId: "G-V2XWL62THN",
  }

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app)
const googleProviderId = GoogleAuthProvider.PROVIDER_ID;
const facebookProviderId = FacebookAuthProvider.PROVIDER_ID;
const emailProviderId = EmailAuthProvider.PROVIDER_ID;

export { auth, db, googleProviderId, facebookProviderId, emailProviderId }