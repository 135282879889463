export default [
  ["Cows", "Cattle (Meat)"],
  ["Dairy", "Cows (Dairy)"],
  ["Chickens", "Chickens (Meat)"],
  ["Eggs", "Layer Hens (Eggs)"],
  ["Pigs", "Pigs"],
  //["Ducks", "Ducks"],
  //["Turkeys", "Turkeys"],
  ["Slaughterhouse", "Slaughterhouses"],
  //["Fish", "Fish & Marine Life"],
  //["Sheep", "Sheep"],
  //["Goats", "Goats"],
  //["Circus", "Circuses"],
  ["Fur", "Fur"],
  ["Lab", "Medical Testing"],
  //["Horses", "Horses"],
  //["Unknown", "Unknown"],
  //["Other", "Miscellaneous"],
  //["Saleyards", "Saleyards"],
  //["Rabbits", "Rabbits"],
  //["Quails", "Quails"]
]
